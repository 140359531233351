import { colorConstants } from "../constants/global-color";

export const GraphOne = (
  themeState,
  data,
  categories,
  title,
  yAxisColor,
  minValue,
  maxValue,
  selectedProjectNumber,
  selectedProjectName
) => {
  const { greyColor, lightgreyColor } = colorConstants;
  const maxDataValue = Math.max(...data);
  const minDataValue = Math.min(...data);

  // Dynamically determine a scale factor based on the data range
  const scaleFactor = maxDataValue > 1 ? 10 : maxDataValue > 0.1 ? 0.1 : 0.01;

  const maxToUse = Math.max(maxValue, maxDataValue);
  const yAxisMaxValue =
    Math.ceil((maxToUse + maxToUse * 0.1) / scaleFactor) * scaleFactor;

  const minToUse = Math.min(minValue, minDataValue);
  const yAxisMinValue =
    minToUse === 0
      ? 0
      : Math.floor((minToUse - minToUse * 0.1) / scaleFactor) * scaleFactor;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
        export: {
          csv: {
            filename: `${selectedProjectNumber}_${selectedProjectName}`,
            columnDelimiter: ",",
            headerCategory: "tlog",
            headerValue: categories,
          },
        },
      },
      // zoom: {
      //   enabled: true,
      // },
      // toolbar: {
      //   show: true,
      //   tools: {
      //     download: true,
      //     selection: false,
      //     zoom: false,
      //     zoomin: true,
      //     zoomout: true,
      //     pan: false,
      //     reset: true,
      //   },
      // },
    },
    stroke: {
      curve: "straight",
      width: [2, 1, 1],
      dashArray: [0, 5, 5],
    },
    xaxis: {
      categories: categories,
      // min: Math.min(...categories),
      // max: Math.max(...categories),
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
        // labels: {
        //   style: {
        //     colors: yAxisColor,
        //   },
        // },
      },
    ],
    markers: {
      size: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor, "#fff", "#fff"],
    legend: {
      show: true,
      showForSingleSeries: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };

  const minSeries = categories.map(() => minValue);
  const maxSeries = categories.map(() => maxValue);

  const series = [
    {
      name: title,
      data: data,
      color: yAxisColor,
    },
    ...(minValue !== 0
      ? [
        {
          name: "Min",
          data: minSeries,
          color: themeState ? greyColor : "#fff",
        },
      ]
      : []),
    ...(maxValue !== 0
      ? [
        {
          name: "Max",
          data: maxSeries,
          color: themeState ? greyColor : "#fff",
        },
      ]
      : []),
  ];

  return { series, options };
};

export const IWMGraph = (
  themeState,
  oscillationWidth,
  categories,
  title,
  yAxisColor,
  minValue,
  maxValue,
  selectedProjectNumber,
  selectedProjectName
) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...oscillationWidth);
  const minDataValue = Math.min(...oscillationWidth);

  // Dynamically determine a scale factor based on the data range
  const scaleFactor = maxDataValue > 1 ? 10 : maxDataValue > 0.1 ? 0.1 : 0.01;

  const maxToUse = Math.max(maxValue, maxDataValue);
  const yAxisMaxValue =
    Math.ceil((maxToUse + maxToUse * 0.1) / scaleFactor) * scaleFactor;

  const minToUse = Math.min(minValue, minDataValue);
  const yAxisMinValue =
    minToUse === 0
      ? 0
      : Math.floor((minToUse - minToUse * 0.1) / scaleFactor) * scaleFactor;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
        export: {
          csv: {
            filename: `${selectedProjectNumber}_${selectedProjectName}`,
            columnDelimiter: ",",
            headerCategory: "tlog",
            headerValue: categories,
          },
        },
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 1, 1],
      dashArray: [0, 5, 5],
    },
    xaxis: {
      categories: categories,
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
      },
    ],
    markers: {
      size: 0, // Hide the markers
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor, "#fff", "#fff"],
  };

  const minSeries = categories.map(() => minValue || 0);
  const maxSeries = categories.map(() => maxValue || 0);

  const series = [
    {
      name: title,
      data: oscillationWidth,
      color: yAxisColor,
    },
    ...(minValue !== 0
      ? [
        {
          name: "Min",
          data: minSeries,
          color: themeState ? greyColor : "#fff",
        },
      ]
      : []),
    ...(maxValue !== 0
      ? [
        {
          name: "Max",
          data: maxSeries,
          color: themeState ? greyColor : "#fff",
        },
      ]
      : []),
  ];

  return { series, options };
};
