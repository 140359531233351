import { Dropdown, Menu, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import IWMGraph from "./IWMGraph";
import { tlogGraphHelperData } from "../../helpers";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { tlogGraphHelper } from "../../helpers/tlog/tlog-graph";
import { tlogHelper } from "../../helpers/tlog/tlog-helper";
// import { convertValue } from "../../helpers/unit-conversion";
import { startTlogsLoader } from "../../redux/tLogData/actions";
import { FETCH_TLOGS_SAGA } from "../../redux/tLogData/type";
import CommonLoader from "../Loaders/loader";
import { MenuOutlined } from "@ant-design/icons";

const { Option } = Select;

const TLogGraph = ({
  s_deviceid_event,
  station_number,
  type,
  zone,
  weld_number,
}) => {
  const reducer = useSelector((state) => state.tlogsReducer);
  const reducerList = useSelector((state) => state);
  const themeState = reducerList?.switchThemeReducer?.switchTheme;
  const loader = reducer?.isTlogsLoading;
  const dispatch = useDispatch();

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const selectedProjectNumber = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_number
  );
  const selectedProject = useSelector(
    (state) => state?.projectReducer?.selectedProject
  );

  const { projectReducer } = useSelector((state) => state);

  const tlogData = reducer?.tlogsList?.allTlogs?.recordList || [];
  const tableLimitData = reducer?.tlogsList?.tableLimitData?.recordList || [];

  const colorOptions = [
    {
      value: "Travel Speed (in/min)",
      label: "Travel Speed (in/min)",
      key: "travelSpeed",
      color: "#e03540",
    },
    {
      value: "Volts (v)",
      key: "volt",
      label: "Volts (v)",
      color: "#FFA500",
    },
    {
      value: "Current (A)",
      key: "current",
      label: "Current (A)",
      color: "#ee82ee",
    },
    {
      value: "Wire Speed (in/min)",
      label: "Wire Speed (in/min)",
      key: "wireSpeed",
      color: "#800080",
    },
    {
      value: "Oscillate Width (in)",
      label: "Oscillate Width (in)",
      key: "oscillationWidth",
      color: "#A52A2A",
    },
    {
      value: "Target (A)",
      label: "Target (A)",
      key: "target",
      color: "#808080",
    },
    {
      value: "Horizontal Bias (A)",
      label: "Horizontal Bias (A)",
      key: "horizontalBias",
      color: "#00FFFF",
    },
    {
      value: "Frequency (cyc/min)",
      label: "Frequency (cyc/min)",
      key: "frequency",
      color: "#FFC0CB",
    },
    {
      value: "Total Wire Consumed (in)",
      label: "Total Wire Consumed (in)",
      key: "totalWireConsumed",
      color: "#FFFF00",
    },
    {
      value: "True Energy (KJ/in)",
      label: "True Energy (KJ/in)",
      key: "trueEnergy",
      color: "#0000FF",
    },
    {
      value: "Heat (kJ/in)",
      label: "Heat (kJ/in)",
      key: "heat",
      color: "#008000",
    },
  ];

  const [reportColumns, setReportColumns] = useState({});
  useEffect(() => {
    if (projectReducer?.selectedProject?.reportColumns) {
      try {
        let parsedData = JSON.parse(
          projectReducer?.selectedProject?.reportColumns
        );
        setReportColumns(parsedData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [projectReducer?.selectedProject?.reportColumns]);

  console.log(
    projectReducer?.selectedProject,
    "projectReducer?.selectedProject"
  );

  useEffect(() => {
    if (selectedProject) {
      const params = {
        project_id: selectedProjectId,
        project_name: selectedProjectName,
        deviceid_event: s_deviceid_event,
        weld_number: weld_number,
        zone: zone,
        type: type,
      };

      dispatch(startTlogsLoader());
      dispatch({ type: FETCH_TLOGS_SAGA, payload: params });
    }
  }, [dispatch, selectedProject, s_deviceid_event, selectedProjectId]);

  const getMaxMinInRangeFilter = ({
    avgTlogItem,
    tableLimitData,
    currentValue,
    type,
    column,
  }) => {
    if (!avgTlogItem?.trail_pass_name && !avgTlogItem?.lead_pass_name) {
      return {
        doesExist: false,
        showColor: "",
      };
    }

    let passNameExtracted = "";
    if (type == "Lead") {
      passNameExtracted = avgTlogItem?.lead_pass_name;
    } else if (type == "Trail") {
      passNameExtracted = avgTlogItem?.trail_pass_name;
    } else {
      passNameExtracted =
        avgTlogItem?.trail_pass_name || avgTlogItem?.lead_pass_name;
    }

    if (passNameExtracted.includes("Head")) {
      passNameExtracted = "HEAD";
    }

    let job_number = avgTlogItem?.job_number;

    let allTableLimitZones = [];

    if (job_number) {
      allTableLimitZones = tableLimitData?.filter(
        (tableLimit) => tableLimit.job_number == job_number
      );
    }

    allTableLimitZones = tableLimitData?.filter(
      (tableLimit) => tableLimit.zone_name == passNameExtracted
    );

    let allPassNames = allTableLimitZones?.map((ite) => ite.pass_name);

    let uniquePassNames = [...new Set([...allPassNames])];

    if (uniquePassNames?.length > 1 || uniquePassNames?.length == 0) {
      return {
        doesExist: false,
        showColor: "",
      };
    } else {
      return {
        doesExist: true,
        showColor:
          allTableLimitZones?.[0]?.[`${column}_min`] &&
            allTableLimitZones?.[0]?.[`${column}_max`]
            ? parseFloat(currentValue) <
              parseFloat(allTableLimitZones?.[0]?.[`${column}_min`]) ||
              parseFloat(currentValue) >
              parseFloat(allTableLimitZones?.[0]?.[`${column}_max`])
              ? "-color"
              : ""
            : "",
      };
    }
  };

  let passNameExtracted = "";
  if (type === "Lead") {
    passNameExtracted = tlogData[0]?.lead_pass_name || "";
  } else if (type === "Trail") {
    passNameExtracted = tlogData[0]?.trail_pass_name || "";
  } else {
    passNameExtracted =
      tlogData[0]?.trail_pass_name || tlogData[0]?.lead_pass_name || "";
  }

  if (passNameExtracted.includes("Head")) {
    passNameExtracted = "HEAD";
  }

  const getValuesForColumns = ({
    leadKeyName,
    trailKeyName,
    column,
    type,
    stationNumber,
    tableLimitData,
    tlog,
    isOnlyLeadFilter,
    currentColumnProjectParam,
  }) => {
    const getFilterResult = (keyName) => {
      return (
        tlog[keyName] +
        getMaxMinInRangeFilter({
          avgTlogItem: tlog,
          column: column,
          type: type,
          currentValue: tlog[keyName],
          tableLimitData: tableLimitData,
          currentColumnProjectParam: currentColumnProjectParam,
        })?.showColor
      );
    };

    if (isOnlyLeadFilter) {
      return type === "Lead"
        ? getFilterResult(leadKeyName)
        : getFilterResult(trailKeyName);
    }

    return type === "Lead" || stationNumber === "0"
      ? getFilterResult(leadKeyName)
      : getFilterResult(trailKeyName);
  };

  const getPassName = (tlog, stationNumber, type, tableLimit) => {
    let tableLimitFiltered = tableLimit?.filter(
      (ite) => ite.job_number == tlog.job_number
    );
    let passName =
      stationNumber == "0"
        ? "Root IWM"
        : type == "Lead" || stationNumber == "0"
          ? tlog.lead_pass_name_actual
          : tlog.trail_pass_name_actual;

    return passName ? passName : tableLimitFiltered.length == 0 ? "-" : "!";
  };

  const getZoneName = (tlog, stationNumber, type) => {
    let zoneName =
      type == "Lead" || stationNumber == "0"
        ? tlog.lead_pass_name
        : tlog.trail_pass_name;

    return zoneName ? zoneName : "!";
  };

  let stationNumber = station_number;
  const tlogParsedData = useMemo(() => {
    return tlogData
      ?.map((tlog, index) => ({
        sl_no: index + 1,
        status:
          type == "Lead"
            ? logDataHelperFunctions.getIconForStatus(tlog.lead_status)
            : logDataHelperFunctions.getIconForStatus(tlog.trail_status),
        event: tlog.deviceid_t_event?.split("_")?.[1],
        time: logDataHelperFunctions.formatWeldStartTime(tlog.time, "", true),
        tilt: tlog.tilt,
        pass: getPassName(tlog, stationNumber, type, tableLimitData),
        zone: getZoneName(tlog, stationNumber, type),
        distance: tlog.distance,
        travelSpeed: getValuesForColumns({
          column: "travel_speed",
          leadKeyName: "travel_speed",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          trailKeyName: "travel_speed",
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.travelSpeed,
        }),
        volts: getValuesForColumns({
          column: "volts",
          leadKeyName: "lead_volts",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          trailKeyName: "trail_volts",
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.arcVolts,
        }),
        current: getValuesForColumns({
          column: "current",
          leadKeyName: "lead_amps",
          trailKeyName: "trail_amps",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.arcCurrent,
        }),
        wireSpeed: getValuesForColumns({
          column: "wire_speed",
          leadKeyName: "lead_wire_speed",
          trailKeyName: "trail_wire_speed",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: true,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.wireSpeed,
        }),
        oscillationWidth: getValuesForColumns({
          column: "oscillation_width",
          leadKeyName: "lead_oscillate_width",
          trailKeyName: "trail_oscillate_width",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: true,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.oscillationWidth,
        }),
        target: type == "Lead" ? tlog.lead_target : tlog.trail_target,
        horizontalBias:
          type == "Lead"
            ? tlog.lead_horizontal_bias
            : tlog.trail_horizontal_bias,
        frequency: type == "Lead" ? tlog.lead_frequency : tlog.trail_frequency,
        totalWireConsumed:
          type == "Lead"
            ? tlog.lead_total_wire_consumed
            : tlog.trail_total_wire_consumed,
        trueEnergy: getValuesForColumns({
          column: "heat",
          leadKeyName: "lead_heat",
          trailKeyName: "trail_heat",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: true,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.heat,
        }),
        heat: getValuesForColumns({
          column: "calculated_heat",
          leadKeyName: "lead_calculated_heat",
          trailKeyName: "trail_calculated_heat",
          stationNumber: stationNumber,
          tableLimitData: tableLimitData,
          tlog: tlog,
          type,
          isOnlyLeadFilter: false,
          currentColumnProjectParam:
            logDataHelperFunctions.allParamsValues.calculatedHeat,
        }),
      }))
      .map((ite) => {
        return tlogHelper.mapTlogWithUnitConversion(ite, reportColumns);
      });
  }, [tlogData, stationNumber, type, tableLimitData]);

  const [selectedOption, setSelectedOption] = useState("Chart 1");
  const [selectedOption2, setSelectedOption2] = useState("Chart 2");
  const [selectedOption3, setSelectedOption3] = useState("Chart 3");
  const [selectedOption4, setSelectedOption4] = useState("Chart 4");
  const [selectedOption5, setSelectedOption5] = useState("Chart 5");
  const [selectedOption6, setSelectedOption6] = useState("Chart 6");

  // const cleanData = (dataArray) =>
  //   dataArray.filter((value) => value !== "" && !isNaN(value));
  const cleanData = (dataArray) =>
    dataArray
      .filter((value) => value !== "" && !isNaN(parseFloat(value)))
      .map((value) => parseFloat(value));
  const categories = cleanData(tlogParsedData?.map((item) => item.tilt) || []);

  const travelSpeeds = cleanData(
    tlogParsedData?.map((item) => item.travelSpeed.split("-")[0]) || []
  );
  const volts = cleanData(
    tlogParsedData?.map((item) => item.volts.split("-")[0]) || []
  );
  const current = cleanData(
    tlogParsedData?.map((item) => item.current.split("-")[0]) || []
  );
  const wireSpeed = cleanData(
    tlogParsedData?.map((item) => item.wireSpeed.split("-")[0]) || []
  );
  const oscillationWidth = cleanData(
    tlogParsedData?.map((item) => item.oscillationWidth.split("-")[0]) || []
  );
  const target = cleanData(
    tlogParsedData?.map((item) => item.target.split("-")[0]) || []
  );
  const horizontalBias = cleanData(
    tlogParsedData?.map((item) => item.horizontalBias.split("-")[0]) || []
  );
  const frequency = cleanData(
    tlogParsedData?.map((item) => item.frequency.split("-")[0]) || []
  );
  const totalWireConsumed = cleanData(
    tlogParsedData?.map((item) => item.totalWireConsumed.split("-")[0]) || []
  );
  const trueEnergy = cleanData(
    tlogParsedData?.map((item) => item.trueEnergy.split("-")[0]) || []
  );
  const heat = cleanData(
    tlogParsedData?.map((item) => item.heat.split("-")[0]) || []
  );

  const empty = [];

  const getMinMaxValues = (column) => {
    return tlogGraphHelper.getMinMaxValuesForGraph({
      column: column,
      tableLimitData: tableLimitData,
      tlogData: tlogData,
      type: type,
      reportColumns: reportColumns,
    });
  };

  let mappedColumns = tlogGraphHelper.mapTlogGraphColumns(
    colorOptions,
    reportColumns
  );

  const getGraphData1 = (selectedOption) => {
    let yAxisColor;
    let yAxisTitle;
    let minValue = 0;
    let maxValue = 0;

    switch (selectedOption) {
      case "Travel Speed (in/min)":
        yAxisColor = "#e03540";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.TRAVEL_SPEED
        )?.label;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          tlogHelper.mappingColumnTableLimit.travelSpeed
        ));
        return tlogGraphHelperData.GraphOne(
          themeState,
          travelSpeeds,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Volts (v)":
        yAxisColor = "#FFA500";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.VOLT_V
        )?.label;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          tlogHelper.mappingColumnTableLimit.volts
        ));
        return tlogGraphHelperData.GraphOne(
          themeState,
          volts,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Current (A)":
        yAxisColor = "#ee82ee";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.CURRENT_A
        )?.label;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          tlogHelper.mappingColumnTableLimit.current
        ));
        return tlogGraphHelperData.GraphOne(
          themeState,
          current,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Wire Speed (in/min)":
        yAxisColor = "#800080";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.WIRE_SPEED
        )?.label;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          tlogHelper.mappingColumnTableLimit.wireSpeed
        ));
        return tlogGraphHelperData.GraphOne(
          themeState,
          wireSpeed,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Oscillate Width (in)":
        yAxisColor = "#A52A2A";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.OSCILLATION_WIDTH
        )?.label;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          tlogHelper.mappingColumnTableLimit.oscillationWidth
        ));
        return tlogGraphHelperData.GraphOne(
          themeState,
          oscillationWidth,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Target (A)":
        yAxisColor = "#808080";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.TARGET
        )?.label;
        return tlogGraphHelperData.GraphOne(
          themeState,
          target,
          categories,
          yAxisTitle,
          yAxisColor,
          0,
          0,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Horizontal Bias (A)":
        yAxisColor = "#00FFFF";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.HORIZONTAL_BIAS
        )?.label;
        return tlogGraphHelperData.GraphOne(
          themeState,
          horizontalBias,
          categories,
          yAxisTitle,
          yAxisColor,
          0,
          0,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Frequency (cyc/min)":
        yAxisColor = "#FFC0CB";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.FREQUENCY
        )?.label;
        return tlogGraphHelperData.GraphOne(
          themeState,
          frequency,
          categories,
          yAxisTitle,
          yAxisColor,
          0,
          0,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Total Wire Consumed (in)":
        yAxisColor = "#FFFF00";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.TOTAL_WIRE_CONSUMED
        )?.label;
        return tlogGraphHelperData.GraphOne(
          themeState,
          totalWireConsumed,
          categories,
          yAxisTitle,
          yAxisColor,
          0,
          0,
          selectedProjectNumber,
          selectedProjectName
        );

      case "True Energy (KJ/in)":
        yAxisColor = "#0000FF";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.TRUE_ENERGY
        )?.label;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          tlogHelper.mappingColumnTableLimit.trueEnergy
        ));
        return tlogGraphHelperData.GraphOne(
          themeState,
          trueEnergy,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      case "Heat (kJ/in)":
        yAxisColor = "#008000";
        yAxisTitle = mappedColumns.find(
          (ite) => ite.key == tlogGraphHelper.columnsMapping.HEAT
        )?.label;
        ({ min: minValue, max: maxValue } = getMinMaxValues(
          tlogHelper.mappingColumnTableLimit.heat
        ));
        return tlogGraphHelperData.GraphOne(
          themeState,
          heat,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );

      default:
        yAxisColor = "#0000FF";
        yAxisTitle = "Chart 1";
        return tlogGraphHelperData.GraphOne(
          themeState,
          empty,
          categories,
          yAxisTitle,
          yAxisColor,
          minValue,
          maxValue,
          selectedProjectNumber,
          selectedProjectName
        );
    }
  };

  const [FirstGraph, setFirstGraph] = useState(getGraphData1(selectedOption));
  const [SecondGraph, setSecondGraph] = useState(
    getGraphData1(selectedOption2)
  );
  const [ThirdGraph, setThirdGraph] = useState(getGraphData1(selectedOption3));
  const [forthGraph, setforthGraph] = useState(getGraphData1(selectedOption4));
  const [fifthGraph, setfifthGraph] = useState(getGraphData1(selectedOption5));
  const [sixthGraph, setsixthGraph] = useState(getGraphData1(selectedOption6));

  useEffect(() => {
    setFirstGraph(getGraphData1(selectedOption));
    setSecondGraph(getGraphData1(selectedOption2));
    setThirdGraph(getGraphData1(selectedOption3));
    setforthGraph(getGraphData1(selectedOption4));
    setfifthGraph(getGraphData1(selectedOption5));
    setsixthGraph(getGraphData1(selectedOption6));
  }, [
    selectedOption,
    selectedOption2,
    selectedOption3,
    selectedOption4,
    selectedOption5,
    selectedOption6,
    themeState,
    tlogParsedData,
  ]);

  const isDataEmpty = FirstGraph.series[0].data.length === 0;
  const isDataEmpty2 = SecondGraph.series[0].data.length === 0;
  const isDataEmpty3 = ThirdGraph.series[0].data.length === 0;
  const isDataEmpty4 = forthGraph.series[0].data.length === 0;
  const isDataEmpty5 = fifthGraph.series[0].data.length === 0;
  const isDataEmpty6 = sixthGraph.series[0].data.length === 0;

  if (!categories.length) {
    return (
      <>
        <IWMGraph
          reportColumns={reportColumns}
          tlogParsedData={tlogParsedData}
          loader={loader}
          tableLimitData={tableLimitData}
          tlogData={tlogData}
          type={type}
        />
      </>
    );
  }

  const downloadChartCSV = (graphData, selectedOption) => {
    const categories = graphData.options.xaxis.categories;
    const mainData = graphData.series[0].data;
    const minData = graphData.series.find(series => series.name === "Min")?.data || [];
    const maxData = graphData.series.find(series => series.name === "Max")?.data || [];


    // Create headers including static min/max values
    let headers = ['Tlog', selectedOption];

    // Only add min/max headers if they have values greater than 0
    if (minData.length > 0 && minData[0] > 0) {
      headers.push('Min Limit');
    }
    if (maxData.length > 0 && maxData[0] > 0) {
      headers.push('Max Limit');
    }
    // Create rows with all data
    const rows = categories.map((category, index) => {
      let row = [category, mainData[index]];

      if (minData.length > 0 && minData[0] > 0) {
        row.push(minData[index]);
      }
      if (maxData.length > 0 && maxData[0] > 0) {
        row.push(maxData[index]);
      }

      return row;
    });

    const csvContent = [
      headers.join(','),
      ...rows.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${selectedProjectNumber}_${selectedProjectName}_${selectedOption}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const getChartMenu = (graphData, selectedOption) => (
    <Menu>
      <Menu.Item onClick={() => downloadChartCSV(graphData, selectedOption)}>
        Download CSV
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="tlog-graph">
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          <div className="grapview-selector">
            <Select
              value={selectedOption}
              onChange={(value) => setSelectedOption(value)}
              className="graph-select"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {tlogGraphHelper
                .mapTlogGraphColumns(colorOptions, reportColumns)
                .map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ textTransform: "math-auto" }}>
                        {option.label}
                      </span>
                    </div>
                  </Option>
                ))}
            </Select>
            <Select
              value={selectedOption2}
              onChange={(value) => setSelectedOption2(value)}
              className="graph-select"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {tlogGraphHelper
                .mapTlogGraphColumns(colorOptions, reportColumns)
                .map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ textTransform: "math-auto" }}>
                        {option.label}
                      </span>
                    </div>
                  </Option>
                ))}
            </Select>
            <Select
              value={selectedOption3}
              onChange={(value) => setSelectedOption3(value)}
              className="graph-select"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {tlogGraphHelper
                .mapTlogGraphColumns(colorOptions, reportColumns)
                .map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ textTransform: "math-auto" }}>
                        {option.label}
                      </span>
                    </div>
                  </Option>
                ))}
            </Select>
            <Select
              value={selectedOption4}
              onChange={(value) => setSelectedOption4(value)}
              className="graph-select"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {tlogGraphHelper
                .mapTlogGraphColumns(colorOptions, reportColumns)
                .map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ textTransform: "math-auto" }}>
                        {option.label}
                      </span>
                    </div>
                  </Option>
                ))}
            </Select>
            <Select
              value={selectedOption5}
              onChange={(value) => setSelectedOption5(value)}
              className="graph-select"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {tlogGraphHelper
                .mapTlogGraphColumns(colorOptions, reportColumns)
                .map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ textTransform: "math-auto" }}>
                        {option.label}
                      </span>
                    </div>
                  </Option>
                ))}
            </Select>

            <Select
              value={selectedOption6}
              onChange={(value) => setSelectedOption6(value)}
              className="graph-select"
              getPopupContainer={trigger => trigger.parentNode}
            >
              {tlogGraphHelper
                .mapTlogGraphColumns(colorOptions, reportColumns)
                .map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      <span style={{ textTransform: "math-auto" }}>
                        {option.label}
                      </span>
                    </div>
                  </Option>
                ))}
            </Select>
          </div>
          <div className="line-graph">
            {!isDataEmpty && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(FirstGraph, selectedOption)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={FirstGraph.options}
                  series={FirstGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty2 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(SecondGraph, selectedOption2)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={SecondGraph.options}
                  series={SecondGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty3 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(ThirdGraph, selectedOption3)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={ThirdGraph.options}
                  series={ThirdGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty4 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(forthGraph, selectedOption4)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={forthGraph.options}
                  series={forthGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty5 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(fifthGraph, selectedOption5)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={fifthGraph.options}
                  series={fifthGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
            {!isDataEmpty6 && (
              <>
                <div className="menuOutlined">
                  <Dropdown
                    overlay={getChartMenu(sixthGraph, selectedOption6)}
                    trigger={["click"]}
                    placement="bottomRight"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <MenuOutlined style={{ fontSize: "16px" }} />
                  </Dropdown>
                </div>
                <Chart
                  options={sixthGraph.options}
                  series={sixthGraph.series}
                  type="line"
                  height={250}
                  className="Graph-background"
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default TLogGraph;
