import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { setSlogsList, startSlogsLoader, stopSlogsLoader } from "./actions";
import { FETCH_SLOGS_SAGA } from "./types";
import { BASE_URL } from "../../config";
import fetch from "../../services/fetch";

function* fetchSlogsSaga(action) {
  try {
    yield put(startSlogsLoader());

    const batchSize = 1000;
    let skip = 0;
    let hasMoreData = true;
    let initialLoad = true; // Flag to indicate initial data load

    while (hasMoreData) {
      const { project_id, project_name } = action.payload;

      const url = new URL(`${BASE_URL}/v1/exposed/slog-details`);
      url.searchParams.append("skip", skip);
      url.searchParams.append("limit", batchSize);
      if (project_id) url.searchParams.append("project_id", project_id);
      if (project_name) url.searchParams.append("project_name", project_name);

      const res = yield fetch(url.toString(), {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      });

      if (res.status === 403) {
        throw new Error("Unauthorized");
      }

      const response = yield res.json();


      switch (response.status) {
        case 200:
          var { allDataSet } = response.data;
          if (allDataSet.length === 0) {
            hasMoreData = false; // Stop if no more data
          } else {
            yield put(setSlogsList(allDataSet, initialLoad));
            initialLoad = false; // Subsequent loads are not initial
            skip += batchSize; // Increment skip for the next batch
          }
          break;
        case 410:
          notification.warn({
            message: response.message,
            duration: 6,
          });
          hasMoreData = false;
          break;
        default:
          notification.error({
            message: "Something went wrong.",
          });
          hasMoreData = false;
      }
    }

    yield put(stopSlogsLoader());
  } catch (error) {
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    yield put(stopSlogsLoader());
  }
}

function* slogsWatcher() {
  yield takeLatest(FETCH_SLOGS_SAGA, fetchSlogsSaga);
}

function* slogsSagas() {
  yield all([call(slogsWatcher)]);
}

export default slogsSagas;
