import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { setTlogsList, startTlogsLoader, stopTlogsLoader } from "./actions";
import { FETCH_TLOGS_SAGA } from "./type";
import { BASE_URL } from "../../config";
import fetch from "../../services/fetch";

function* fetchTlogsSaga(action) {
  try {
    yield put(startTlogsLoader());

    const {
      project_name,
      type,
      project_id,
      zone,
      deviceid_event,
      weld_number,
    } = action.payload;

    const url = new URL(`${BASE_URL}/v1/exposed/t-logs`);
    if (project_id) url.searchParams.append("project_id", project_id);
    if (project_name) url.searchParams.append("project_name", project_name);
    if (weld_number) url.searchParams.append("weld_number", weld_number);
    if (zone) url.searchParams.append("zone", zone);
    if (type) url.searchParams.append("type", type);

    if (deviceid_event)
      url.searchParams.append("deviceid_event", deviceid_event);

    const res = yield fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        yield put(setTlogsList(response?.data));
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
    }

    yield put(stopTlogsLoader());
  } catch (error) {
    notification.error({
      message: error.message || "Failed to fetch tlogs.",
    });
    yield put(stopTlogsLoader());
  }
}
function* tlogsWatcher() {
  yield takeLatest(FETCH_TLOGS_SAGA, fetchTlogsSaga);
}

function* tlogsSagas() {
  yield all([call(tlogsWatcher)]);
}

export default tlogsSagas;
