import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import {
  setZoneviewList,
  startZoneviewLoader,
  stopZoneviewLoader,
} from "./actions";
import { FETCH_ZONEVIEW_SAGA } from "./type";
import { BASE_URL } from "../../config";
import fetch from "../../services/fetch";

function* fetchZoneViewSaga(action) {
  try {
    yield put(startZoneviewLoader());

    const { project_id, weld_number, project_name } = action.payload;

    const url = new URL(`${BASE_URL}/v1/exposed/log-data-zone-view`);
    if (project_id) url.searchParams.append("project_id", project_id);
    if (weld_number) url.searchParams.append("weld_number", weld_number);
    if (project_name) url.searchParams.append("project_name", project_name);

    const res = yield fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        yield put(setZoneviewList(response?.data));
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
    }

    yield put(stopZoneviewLoader());
  } catch (error) {
    notification.error({
      message: error.message || "Failed to fetch slogs.",
    });
    yield put(stopZoneviewLoader());
  }
}
function* zoneviewWatcher() {
  yield takeLatest(FETCH_ZONEVIEW_SAGA, fetchZoneViewSaga);
}

function* zoneviewSagas() {
  yield all([call(zoneviewWatcher)]);
}

export default zoneviewSagas;
