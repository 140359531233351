import { Input, Table, notification } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { commonConstants } from "../../constants";
import CommonLoader from "../Loaders/loader";

const PqrTable = ({
  visibleColumns,
  weld_number,
  PqrReportData,
  setIsEdited,
  maxMinData,
  editedDataFromTable,
  setEditedDataFromTable,
  loadPqrData,
  boundary,
}) => {
  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);

  const history = useHistory();
  const loader = useSelector((state) => state.pqrdataReducer.isPqrDataLoding);
  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const paginationOptions = {
    pageSize,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: commonConstants.ZonePageSizeOptions,
  };

  const getScrollHeight = () => {
    const screenHeight = window.innerHeight;
    if (screenHeight >= 832 && screenHeight <= 956) {
      return 620;
    } else {
      if (screenHeight >= 750 && screenHeight <= 832) {
        return 500;
      }
    }
    return 410;
  };

  const toFixedValue = (value, digits = 2) => {
    return value !== undefined && value !== null ? value.toFixed(digits) : 0;
  };

  const createDataFromPqrReport = () => {
    const { calculatedSummary } = PqrReportData;

    if (!calculatedSummary) return [];

    const passes = Object.keys(calculatedSummary);

    return passes.map((pass) => {
      const passData = calculatedSummary[pass] || {};
      const minMaxForPass = Array.isArray(maxMinData)
        ? maxMinData.find((data) => data.passName === pass) || {}
        : {};

      return {
        key: pass,
        passName: pass,
        preHeatMin:
          minMaxForPass.preHeatInterPassTemperatureMin ||
          toFixedValue(passData.wpsLimitMin?.preHeatInterPassTemperature),
        preHeatMax:
          minMaxForPass.preHeatInterPassTemperatureMax ||
          toFixedValue(passData.wpsLimitMax?.preHeatInterPassTemperature),
        voltsMin: toFixedValue(passData.wpsLimitMin?.averageVoltage),
        voltsMax: toFixedValue(passData.wpsLimitMax?.averageVoltage),
        ampsMin: toFixedValue(passData.wpsLimitMin?.averageCurrent),
        ampsMax: toFixedValue(passData.wpsLimitMax?.averageCurrent),
        minWireFeedSpeed: toFixedValue(
          passData.wpsLimitMin?.averageWireFeedSpeed
        ),
        maxWireFeedSpeed: toFixedValue(
          passData.wpsLimitMax?.averageWireFeedSpeed
        ),
        oscWidthMin: toFixedValue(
          passData.wpsLimitMin?.averageOscillationWidth
        ),
        oscWidthMax: toFixedValue(
          passData.wpsLimitMax?.averageOscillationWidth
        ),
        oscFrqMin: toFixedValue(
          passData.wpsLimitMin?.averageOscillationFrequency
        ),
        oscFrqMax: toFixedValue(
          passData.wpsLimitMax?.averageOscillationFrequency
        ),
        travelSpeedMin: toFixedValue(passData.wpsLimitMin?.averageTravelSpeed),
        travelSpeedMax: toFixedValue(passData.wpsLimitMax?.averageTravelSpeed),
        arcEnergyMin:
          minMaxForPass.arcEnergyMin ||
          toFixedValue(passData.wpsLimitMin?.arcEnergy),
        arcEnergyMax:
          minMaxForPass.arcEnergyMax ||
          toFixedValue(passData.wpsLimitMax?.arcEnergy),
        pulseFreqMin:
          minMaxForPass.averagePulseFrequencyMin ||
          toFixedValue(passData.wpsLimitMin?.averagePulseFrequency),
        pulseFreqMax:
          minMaxForPass.averagePulseFrequencyMax ||
          toFixedValue(passData.wpsLimitMax?.averagePulseFrequency),
        pulseTimeMin:
          minMaxForPass.averagePulseTimeMin ||
          toFixedValue(passData.wpsLimitMin?.averagePulseTime),
        pulseTimeMax:
          minMaxForPass.averagePulseTimeMax ||
          toFixedValue(passData.wpsLimitMax?.averagePulseTime),
        peakCurrentMin:
          minMaxForPass.averagePeakCurrentMin ||
          toFixedValue(passData.wpsLimitMin?.averagePeakCurrent),
        peakCurrentMax:
          minMaxForPass.averagePeakCurrentMax ||
          toFixedValue(passData.wpsLimitMax?.averagePeakCurrent),
        baseCurrentMin:
          minMaxForPass.averageBaseCurrentMin ||
          toFixedValue(passData.wpsLimitMin?.averageBaseCurrent),
        baseCurrentMax:
          minMaxForPass.averageBaseCurrentMax ||
          toFixedValue(passData.wpsLimitMax?.averageBaseCurrent),
      };
    });
  };

  const Data = createDataFromPqrReport();

  const handleRowClick = (record) => {
    let paramsPass = {
      passname: record.passName,
      weld_number,
      boundary: boundary,
    };
    history.push(`/wps-limit-table?boundary=${boundary}`, {
      ...paramsPass,
    });
  };

  const validKeys = [
    "preHeatMin",
    "preHeatMax",
    "arcEnergyMin",
    "arcEnergyMax",
    "pulseFreqMin",
    "pulseFreqMax",
    "pulseTimeMin",
    "pulseTimeMax",
    "peakCurrentMin",
    "peakCurrentMax",
    "baseCurrentMin",
    "baseCurrentMax",
  ];

  const [inputErrors, setInputErrors] = useState({});

  const getFieldGroup = (fieldName) => {
    return fieldName.replace(/(Min|Max)$/, "");
  };

  console.log(editedDataFromTable, "editedDataFromTable");

  const handleInputChange = (dataIndex, value, recordKey) => {
    if (!value || isNaN(value)) {
      notification.error({
        message: `${dataIndex} cannot be empty and must be a number.`,
        key: `validation-error-${dataIndex}`,
        duration: 3,
      });
      setInputErrors((prev) => ({
        ...prev,
        [`${recordKey}-${getFieldGroup(dataIndex)}`]: {
          min: dataIndex.includes("Min"),
          max: dataIndex.includes("Max"),
        },
      }));
      return;
    }

    const numericValue = parseFloat(value);

    setEditedDataFromTable((prev) => {
      const existingRecordIndex = prev.findIndex(
        (item) => item.key == recordKey
      );

      const originalRecord = Data.find((item) => item.key === recordKey);

      const existingRecord =
        existingRecordIndex > -1
          ? prev[existingRecordIndex]
          : originalRecord
          ? { ...originalRecord }
          : { key: recordKey, passName: recordKey };

      const updatedRecord = {
        ...existingRecord,
        [dataIndex]: numericValue,
      };

      const isMinField = dataIndex.includes("Min");
      const correspondingField = isMinField
        ? dataIndex.replace("Min", "Max")
        : dataIndex.replace("Max", "Min");

      let correspondingValue;
      if (existingRecordIndex > -1) {
        correspondingValue = existingRecord[correspondingField];
      } else {
        correspondingValue = originalRecord
          ? originalRecord[correspondingField]
          : null;
      }

      const parsedCorrespondingValue =
        correspondingValue !== null
          ? parseFloat(correspondingValue)
          : isMinField
          ? Infinity
          : -Infinity;

      const fieldGroup = getFieldGroup(dataIndex);

      if (isMinField) {
        if (numericValue > parsedCorrespondingValue) {
          setInputErrors((prev) => ({
            ...prev,
            [`${recordKey}-${fieldGroup}`]: {
              min: true,
              max: true,
            },
          }));
        } else {
          setInputErrors((prev) => ({
            ...prev,
            [`${recordKey}-${fieldGroup}`]: {
              min: false,
              max: false,
            },
          }));
        }
      } else {
        if (numericValue < parsedCorrespondingValue) {
          setInputErrors((prev) => ({
            ...prev,
            [`${recordKey}-${fieldGroup}`]: {
              min: true,
              max: true,
            },
          }));
        } else {
          setInputErrors((prev) => ({
            ...prev,
            [`${recordKey}-${fieldGroup}`]: {
              min: false,
              max: false,
            },
          }));
        }
      }

      if (existingRecordIndex > -1) {
        return [
          ...prev.slice(0, existingRecordIndex),
          updatedRecord,
          ...prev.slice(existingRecordIndex + 1),
        ];
      } else {
        return [...prev, updatedRecord];
      }
    });

    setIsEdited(true);
  };

  return (
    <div className="pass-zone">
      {loader || loadPqrData ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          {Data && Data.length > 0 ? (
            <Table
              className="zoneview-table pqrstyle"
              dataSource={Data}
              scroll={{ x: 2000, y: getScrollHeight() }}
              pagination={paginationOptions}
              onRow={(record) => ({
                onClick: () => {
                  handleRowClick(record);
                },
              })}
            >
              {visibleColumns.map((column) =>
                column.children ? (
                  <Table.ColumnGroup key={column.key} title={column.title}>
                    {column.children.map((child) =>
                      validKeys.includes(child.key) ? (
                        <Table.Column
                          key={child.key}
                          title={child.title}
                          dataIndex={child.dataIndex}
                          width={column.width || 100}
                          render={(text, record) => (
                            <Input
                              style={
                                inputErrors[
                                  `${record.key}-${getFieldGroup(
                                    child.dataIndex
                                  )}`
                                ]?.[
                                  child.dataIndex.includes("Min")
                                    ? "min"
                                    : "max"
                                ]
                                  ? { textAlign: "center", color: "#e03540" }
                                  : { textAlign: "center" }
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  child.dataIndex,
                                  e.target.value,
                                  record.key
                                )
                              }
                              defaultValue={record[child.dataIndex]}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              inputMode="numeric"
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^0-9.]/g,
                                  ""
                                );
                              }}
                            />
                          )}
                        />
                      ) : (
                        <Table.Column
                          key={child.key}
                          title={child.title}
                          dataIndex={child.dataIndex}
                          width={column.width || 100}
                        />
                      )
                    )}
                  </Table.ColumnGroup>
                ) : (
                  <Table.Column
                    key={column.key}
                    title={column.title}
                    dataIndex={column.dataIndex}
                    width={column.width || 100}
                  />
                )
              )}
            </Table>
          ) : (
            <div className="slog-NoData">
              <p>No Data Available</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PqrTable;
