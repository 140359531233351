import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ExclamationIcon from "../../assets/exclamation.svg";
import { commonConstants } from "../../constants";
import { logDataHelperFunctions } from "../../helpers/logdata-helper";
import { zoneViewHelper } from "../../helpers/zone-view/zone-view-helper";
import CommonLoader from "../Loaders/loader";

const ZoneView = ({ visibleColumns, weld_number }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { zoneviewList, isZoneviewLoding } = useSelector(
    (state) => state.zoneviewReducer
  );

  const { projectReducer } = useSelector((state) => state);

  const [pageSize, setPageSize] = useState(commonConstants.defaultPageSize);

  const [weldParamsShowColumns, setWeldParamsShowColumns] = useState([]);

  const [reportColumns, setReportColumns] = useState(
    logDataHelperFunctions.sampleReportColumns
  );

  useEffect(() => {
    if (projectReducer?.selectedProject?.reportColumns) {
      try {
        let parsedData = JSON.parse(
          projectReducer?.selectedProject?.reportColumns
        );
        setReportColumns(parsedData);
      } catch (e) {
        console.log(e);
      }
    }
  }, [projectReducer?.selectedProject?.reportColumns]);

  useEffect(() => {
    if (zoneviewList?.weldParams?.length > 0) {
      let allMappedValues = zoneviewList?.weldParams
        ?.map((ite) => {
          try {
            let params = ite.parameter_check_list || {};
            let enabledKeys = [];
            for (let key of Object.keys(params)) {
              if (params[key]) {
                enabledKeys.push(key);
              }
            }

            return {
              job_number: ite.job_number,
              enabledKeys:
                enabledKeys?.length > 0
                  ? enabledKeys
                  : logDataHelperFunctions.defaultParamsValues,
            };
          } catch (e) {
            console.log(e);
            return null;
          }
        })
        .filter((ite) => ite != null);

      setWeldParamsShowColumns(allMappedValues);
    }
  }, [zoneviewList?.weldParams]);

  const slogData = useSelector((state) => state.slogReducer.slogsList) || [];

  const zoneviewData = zoneviewList?.avgTlogList?.recordList || [];
  const loader = isZoneviewLoding;

  const tableLimitData = zoneviewList?.tableLimitData?.recordList || [];

  // Create a mapping of deviceid_event to station_number and bug_type
  const deviceMapping = slogData.reduce((acc, item) => {
    item.slogs.forEach((slog) => {
      acc[slog.deviceid_event] = {
        station_number: slog.station_number,
        bug_type: slog.bug_type,
        ...slog,
      };
    });
    return acc;
  }, {});

  const getMaxMinInRangeFilter = ({
    avgTlogItem,
    currentValue,
    column,
    columnParamsKey,
  }) => {
    if (!avgTlogItem?.trail_pass_name && !avgTlogItem?.lead_pass_name) {
      return {
        doesExist: false,
        showColor: "",
      };
    }

    let passNameExtracted =
      avgTlogItem?.trail_pass_name || avgTlogItem?.lead_pass_name;

    if (passNameExtracted.includes("Head")) {
      passNameExtracted = "HEAD";
    }

    let job_number = avgTlogItem?.job_number;

    let allTableLimitZones = [];

    if (job_number) {
      allTableLimitZones = tableLimitData?.filter(
        (tableLimit) => tableLimit.job_number == job_number
      );

      let enabled = logDataHelperFunctions.isColumnEnabledForRedCircle({
        currentColumn: columnParamsKey,
        jobNumber: job_number,
        weldParamsShowColumns: weldParamsShowColumns,
      });

      if (!enabled) {
        return {
          doesExist: false,
          showColor: "",
        };
      }
    }

    allTableLimitZones = allTableLimitZones?.filter(
      (tableLimit) => tableLimit.zone_name == passNameExtracted
    );

    let allPassNames = allTableLimitZones?.map((ite) => ite.pass_name);

    let uniquePassNames = [...new Set([...allPassNames])];

    if (uniquePassNames?.length > 1 || uniquePassNames?.length == 0) {
      return {
        doesExist: false,
        showColor: "",
      };
    } else {
      return {
        doesExist: true,
        showColor:
          allTableLimitZones?.[0]?.[`${column}_min`] &&
          allTableLimitZones?.[0]?.[`${column}_max`]
            ? Number(currentValue) <
                Number(allTableLimitZones?.[0]?.[`${column}_min`]) ||
              Number(currentValue) >
                Number(allTableLimitZones?.[0]?.[`${column}_max`])
              ? "-color"
              : ""
            : "",
      };
    }
  };

  const getHighLowColumnsValue = ({
    columnForTableLimit,
    keyName,
    avgTlogItem,
    station_number,
    isStationFilterRequired,
    columnParamsKey,
  }) => {
    if (
      (isStationFilterRequired && station_number === "0") ||
      !avgTlogItem[keyName]
    ) {
      return "";
    }

    let keysForToFixed = [
      "amps_avg",
      "volts_avg",
      "travel_speed_avg",
      "heat_avg",
      "calculated_heat_avg",
      "wire_speed_avg",
      "oscillate_width_avg",
    ];
    const value = keysForToFixed.includes(keyName)
      ? parseFloat(avgTlogItem[keyName]).toFixed(2)
      : avgTlogItem[keyName];

    return (
      value +
      `${
        getMaxMinInRangeFilter({
          avgTlogItem: avgTlogItem,
          column: columnForTableLimit,
          currentValue: value,
          columnParamsKey: columnParamsKey,
        }).showColor
      }`
    );
  };

  let dataSourceALL = zoneviewData
    .map((item, index) => {
      const deviceInfo = deviceMapping[item.s_deviceid_event] || {};
      const zoneValue = item.lead_pass_name || item.trail_pass_name || "-";
      const torchValue =
        deviceInfo.station_number == "0"
          ? ""
          : item.lead_pass_name
          ? "Lead"
          : item.trail_pass_name
          ? "Trail"
          : "-";
      const weldStartTime = logDataHelperFunctions.formatWeldStartTime(
        item.s_time,
        true
      );
      const stationName = logDataHelperFunctions.getStationName(
        deviceInfo.station_number
      );

      const specificBuyType = deviceInfo.bug_type || "-";
      const buyTypeDisplay = specificBuyType.includes(" / ")
        ? specificBuyType.split(" / ")[0]
        : specificBuyType;
      const passName = logDataHelperFunctions.getPassNameSingleTlog({
        tlogsSingle: item,
        station_number: deviceInfo.station_number,
        tableLimitData: tableLimitData,
        weld_number: deviceInfo?.weld_number,
      });

      return {
        key: index,
        sl_no: index + 1,
        start_time: deviceInfo?.time,
        end_time: deviceInfo?.c_time,
        autoIncrement: deviceInfo?.auto_increment || "-",
        bug_number: deviceInfo?.bug_number,
        lead_wire_diameter: deviceInfo.lead_wire_diameter,
        pipe_diameter: deviceInfo.pipe_diameter,
        gps: deviceInfo.gps,
        iwm: deviceInfo.iwm,
        job_number: deviceInfo.job_number,
        trail_wire_diameter: deviceInfo.trail_wire_diameter,
        band_diameter: deviceInfo?.band_diameter,
        logging_distance: deviceInfo?.logging_distance,
        lead_pass_name: item?.lead_pass_name,
        trail_pass_name: item?.trail_pass_name,
        status: item.status ?? "",
        stationNumbers: stationName,
        station_number: deviceInfo.station_number,
        s_deviceid_event: item?.s_deviceid_event,
        buyType: buyTypeDisplay,
        type: torchValue,
        pass: passName,
        zone: zoneValue,
        weldStartTime: weldStartTime,
        weld_time: item.weld_time ?? "",
        current_low: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "current",
          isStationFilterRequired: false,
          keyName: "amps_low",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.arcCurrent,
        }),
        current_avg: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "current",
          isStationFilterRequired: false,
          keyName: "amps_avg",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.arcCurrent,
        }),
        current_high: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "current",
          isStationFilterRequired: false,
          keyName: "amps_high",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.arcCurrent,
        }),
        volt_low: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "volts",
          isStationFilterRequired: false,
          keyName: "volts_low",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.arcVolts,
        }),
        volt_avg: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "volts",
          isStationFilterRequired: false,
          keyName: "volts_avg",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.arcVolts,
        }),
        volt_high: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "volts",
          isStationFilterRequired: false,
          keyName: "volts_high",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.arcVolts,
        }),
        travel_speed_low: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "travel_speed",
          isStationFilterRequired: false,
          keyName: "travel_speed_low",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.travelSpeed,
        }),
        travel_speed_avg: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "travel_speed",
          isStationFilterRequired: false,
          keyName: "travel_speed_avg",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.travelSpeed,
        }),
        travel_speed_high: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "travel_speed",
          isStationFilterRequired: false,
          keyName: "travel_speed_high",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.travelSpeed,
        }),
        true_energy_low: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "heat",
          isStationFilterRequired: true,
          keyName: "heat_low",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.heat,
        }),
        true_energy_avg: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "heat",
          isStationFilterRequired: true,
          keyName: "heat_avg",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.heat,
        }),
        true_energy_high: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "heat",
          isStationFilterRequired: true,
          keyName: "heat_high",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.heat,
        }),
        heat_low: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "calculated_heat",
          isStationFilterRequired: false,
          keyName: "calculated_heat_low",
          station_number: deviceInfo.station_number,
          columnParamsKey:
            logDataHelperFunctions.allParamsValues.calculatedHeat,
        }),
        heat_avg: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "calculated_heat",
          isStationFilterRequired: false,
          keyName: "calculated_heat_avg",
          station_number: deviceInfo.station_number,
          columnParamsKey:
            logDataHelperFunctions.allParamsValues.calculatedHeat,
        }),
        heat_high: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "calculated_heat",
          isStationFilterRequired: false,
          keyName: "calculated_heat_high",
          station_number: deviceInfo.station_number,
          columnParamsKey:
            logDataHelperFunctions.allParamsValues.calculatedHeat,
        }),
        wire_speed_low: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "wire_speed",
          isStationFilterRequired: false,
          keyName: "wire_speed_low",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.wireSpeed,
        }),
        wire_speed_avg: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "wire_speed",
          isStationFilterRequired: false,
          keyName: "wire_speed_avg",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.wireSpeed,
        }),
        wire_speed_high: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "wire_speed",
          isStationFilterRequired: false,
          keyName: "wire_speed_high",
          station_number: deviceInfo.station_number,
          columnParamsKey: logDataHelperFunctions.allParamsValues.wireSpeed,
        }),
        oscillation_width_low: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "oscillation_width",
          isStationFilterRequired: false,
          keyName: "oscillate_width_low",
          station_number: deviceInfo.station_number,
          columnParamsKey:
            logDataHelperFunctions.allParamsValues.oscillationWidth,
        }),
        oscillation_width_avg: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "oscillation_width",
          isStationFilterRequired: false,
          keyName: "oscillate_width_avg",
          station_number: deviceInfo.station_number,
          columnParamsKey:
            logDataHelperFunctions.allParamsValues.oscillationWidth,
        }),
        oscillation_width_high: getHighLowColumnsValue({
          avgTlogItem: item,
          columnForTableLimit: "oscillation_width",
          isStationFilterRequired: false,
          keyName: "oscillate_width_high",
          station_number: deviceInfo.station_number,
          columnParamsKey:
            logDataHelperFunctions.allParamsValues.oscillationWidth,
        }),
        frequency_low: item.frequency_low ?? "",
        frequency_avg: item.frequency_avg
          ? parseFloat(item.frequency_avg).toFixed(2)
          : "",
        frequency_high: item.frequency_high ?? "",
        target_low: item.target_low ?? "",
        target_avg: item.target_avg
          ? parseFloat(item.target_avg).toFixed(2)
          : "",
        target_high: item.target_high ?? "",
        horizontal_bias_low: item.horizontal_bias_low ?? "",
        horizontal_bias_avg: item.horizontal_bias_avg
          ? parseFloat(item.horizontal_bias_avg).toFixed(2)
          : "",
        horizontal_bias_high: item.horizontal_bias_high ?? "",
        total_wire_consumed_low: item.total_wire_consumed_low ?? "",
        total_wire_consumed_avg: item.total_wire_consumed_avg
          ? parseFloat(item.total_wire_consumed_avg).toFixed(2)
          : "",
        total_wire_consumed_high: item.total_wire_consumed_high ?? "",
      };
    })
    .sort((a, b) => {
      if (a.stationNumbers < b.stationNumbers) return -1;
      if (a.stationNumbers > b.stationNumbers) return 1;
      return 0;
    })
    .sort((a, b) => {
      return a.buyType.length - b.buyType.length;
    })
    .sort((a, b) => {
      if (a.stationNumbers < b.stationNumbers) return -1;
      if (a.stationNumbers > b.stationNumbers) return 1;
      return 0;
    });
  // .sort((a, b) => {
  //   return (
  //     Number(a.s_deviceid_event.split("_")[1]) -
  //     Number(b.s_deviceid_event.split("_")[1])
  //   );
  // });

  dataSourceALL = zoneViewHelper.zoneViewSorting(dataSourceALL);

  const dataSource = dataSourceALL
    .filter(
      (item) =>
        item.current_avg != "" ||
        item.volt_avg != "" ||
        item.wire_speed_avg != "" ||
        item.oscillation_width_avg != "" ||
        item.target_avg != "" ||
        item.horizontal_bias_avg != "" ||
        item.frequency_avg != "" ||
        item.total_wire_consumed_avg != "" ||
        item.heat_avg != ""
    )
    .map((ite, index) => ({
      ...ite,
      sl_no: index + 1,
    }))
    ?.map((ite) => {
      console.log(ite, "items inside before conversion");
      return zoneViewHelper.mapperForUnitConversionZoneView(ite, reportColumns);
    });

  const handleRowClick = (record) => {
    const {
      stationNumbers,
      station_number,
      buyType,
      type,
      zone,
      s_deviceid_event,
      weld_time,
      start_time,
      end_time,
      band_diameter,
      logging_distance,
      bug_number,
      lead_wire_diameter,
      trail_wire_diameter,
      pipe_diameter,
      autoIncrement,
      gps,
      iwm,
    } = record;
    history.push("/tlog-data", {
      stationNumbers,
      station_number,
      buyType,
      type,
      zone,
      weld_number,
      s_deviceid_event: s_deviceid_event,
      weld_time,
      start_time,
      end_time,
      band_diameter,
      logging_distance,
      bug_number,
      lead_wire_diameter,
      trail_wire_diameter,
      pipe_diameter,
      autoIncrement,
      gps,
      iwm,
    });
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const paginationOptions = {
    pageSize,
    onShowSizeChange: handlePageSizeChange,
    showSizeChanger: true,
    pageSizeOptions: commonConstants.ZonePageSizeOptions,
  };

  const getScrollHeight = () => {
    const screenHeight = window.innerHeight;
    if (screenHeight >= 832 && screenHeight <= 956) {
      return 620;
    } else {
      if (screenHeight >= 750 && screenHeight <= 832) {
        return 500;
      }
    }
    return 410;
  };

  return (
    <div>
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : dataSource.length > 0 ? (
        <Table
          className="zoneview-table"
          dataSource={dataSource}
          scroll={{ x: 5500, y: getScrollHeight() }}
          pagination={paginationOptions}
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        >
          {zoneViewHelper
            .mapZoneViewColumnTitles(visibleColumns, reportColumns)
            .map((column) =>
              column.children ? (
                <Table.ColumnGroup key={column.key} title={column.title}>
                  {column.children.map((child) => (
                    <Table.Column
                      key={child.key}
                      title={child.title}
                      dataIndex={child.dataIndex}
                      width={child.width || 100}
                      render={(text) => {
                        if (child.dataIndex === "status") {
                          return logDataHelperFunctions.getIconForStatus(text);
                        }
                        if (text.includes("-color")) {
                          return (
                            <span className="red-note">
                              {text.replace("-color", "")}
                            </span>
                          );
                        }
                        return text;
                      }}
                    />
                  ))}
                </Table.ColumnGroup>
              ) : (
                <Table.Column
                  key={column.key}
                  title={column.title}
                  dataIndex={column.dataIndex}
                  width={column.width || 100}
                  render={(text) => {
                    if (column.dataIndex === "status") {
                      return logDataHelperFunctions.getIconForStatus(text);
                    }
                    if (column.dataIndex == "pass") {
                      return text == "!" ? (
                        <img src={ExclamationIcon} alt="Exclamation" />
                      ) : (
                        text
                      );
                    }
                    if (column.dataIndex == "zone") {
                      return text == "-" ? (
                        <img src={ExclamationIcon} alt="Exclamation" />
                      ) : (
                        text
                      );
                    }
                    return text;
                  }}
                />
              )
            )}
        </Table>
      ) : (
        <div className="slog-NoData">
          <p>{t("sLogData.noDataAvailable")}</p>
        </div>
      )}
    </div>
  );
};

export default ZoneView;
